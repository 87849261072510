import { defineStore } from "pinia"
import { get, isEmpty, isEqual, sortBy } from "lodash"
import { Product } from "@/types/product"
import { Cart } from "@/types/cart/cart"
import { ApiResponse } from "@/types/apiResponse"
import { FinalConfiguration, FinalConfigurationHeader } from "@/types/teamOrder"
import { AddToCart, LineItem } from "@/models/addToCart.model"
import { CONSTANT } from "@/helpers/constant"
import { HttpService } from "@/helpers/http-service"
import formatPrice from "@/helpers/format-price"
import {
  _getValue,
  downloadCSV,
  getCalculatedPriceObject,
  getCustomConfigurationArrayV2,
  getCustomisationName,
  getCustomizationNameFromOption,
  getMultiplicationOfTwoNumbersWithFractionDigits,
  getOptionPrice,
  getPreFilledInputCustomisation,
  getSumOfTwoNumbersWithFractionDigits,
  getTotalValue,
  getUserInputCustomisation,
  getValueLength,
  initRegExForExpertMode,
  isValidationErrorInExpertMode,
  parseConfigurationFromItemObject,
  prepareCartObject,
  removeEmptyOptionalCustomizationV2,
} from "@/helpers/common"
import { _calculateFinishesPriceInNumber, getAssortmentProductMediaCoverUrl, getAssortmentProductMediaUrl } from "@/helpers/assortment-item-service"
import { EecomTpAssortmentProduct } from "@/types/eecomTpAssortmentProduct"
import { useCustomerStore } from "./customer.store"
import { useSystemAndContextStore } from "./systemAndContext.store"
import { useProductRangeStore } from "./productRange.store"
import { IndexDBChanged } from "@/helpers/behavior-service"
import { IndexedDbService } from "@/helpers/indexed-db-service"

interface State {
  assortmentProductsInfo: any
  finalConfiguration: FinalConfiguration
  checkoutCartObj: any
  orderReference: string
  customizationTypeObject: any
}

const httpService = new HttpService()
const httpServiceWithoutLoader = new HttpService(false)

export const useTeamOrderStore = defineStore({
  id: "teamOrder",
  state: (): State => ({
    assortmentProductsInfo: [],
    finalConfiguration: {} as FinalConfiguration,
    checkoutCartObj: {},
    orderReference: "",
    customizationTypeObject: {
      initials: {
        mandatoryCustomization: {},
        optionalCustomization: {},
      },
      playername: {
        mandatoryCustomization: {},
        optionalCustomization: {},
      },
      number: {
        mandatoryCustomization: {},
        optionalCustomization: {},
      },
      expertMode: {
        mandatoryCustomization: {},
        optionalCustomization: {},
      },
    },
  }),
  getters: {
    getProductsInfo: (state) => {
      return state.assortmentProductsInfo
    },
  },
  actions: {
    getProductName(assortmentProduct: EecomTpAssortmentProduct) {
      return _getValue(assortmentProduct, "product.name", _getValue(assortmentProduct, "product.translated.name", CONSTANT.COMMON.FIELD_NULL).value).value
    },

    getProductNumber(assortmentProduct: EecomTpAssortmentProduct, t: any) {
      const productNumber = _getValue(assortmentProduct, "product.productNumber", CONSTANT.COMMON.FIELD_NULL).value
      const productNumberText = t("eecom-teamportal.sales-force.product-range.product-number")
      return productNumberText.replace(new RegExp("%ARTICLE_NO%", "g"), productNumber)
    },

    getMPN(assortmentProduct: EecomTpAssortmentProduct, t: any) {
      const mpn = _getValue(assortmentProduct, "product.manufacturerNumber", CONSTANT.COMMON.FIELD_NULL).value
      const mpnText = t("eecom-teamportal.sales-force.product-range.mpn")
      return mpnText.replace(new RegExp("%MPN_VALUE%", "g"), mpn)
    },

    getAssortmentProductMedia(assortmentProduct: EecomTpAssortmentProduct) {
      const data = sortBy(assortmentProduct?.eecomTpAssortmentProductMedia, function (o) {
        return !o.isCover
      })

      const images = data?.map((item: any) => ({
        id: item.id,
        path: getAssortmentProductMediaUrl(item),
      }))
      return images
    },

    getAssortmentProductMediaCover(assortmentProduct: EecomTpAssortmentProduct) {
      const images = [
        {
          id: assortmentProduct.id,
          path: getAssortmentProductMediaCoverUrl(assortmentProduct),
        },
      ]
      return images
    },

    getUnitPriceValue(assortmentProduct: EecomTpAssortmentProduct, totalQuantites: number) {
      const customerStore = useCustomerStore()
      const systemAndContextStore = useSystemAndContextStore()

      const calculatedPrice = getCalculatedPriceObject(assortmentProduct?.product, totalQuantites)
      const assortmentProductPrice = _getValue(assortmentProduct, "price[0]", null).value
      const customer = customerStore.customerData
      const displayGross = _getValue(customer, "group.displayGross", false).value
      let unitPrice: number = 0
      if (assortmentProductPrice) {
        unitPrice = _getValue(assortmentProductPrice, `${displayGross ? "gross" : "net"}`, 0).value
        if (systemAndContextStore.currentContext?.context?.taxState === "gross") {
          unitPrice = _getValue(assortmentProductPrice, "gross", 0).value
        } else {
          unitPrice = _getValue(assortmentProductPrice, "net", 0).value
        }
      } else {
        const customerRegularDiscount = get(customer, "customFields.eecom_tp_customer_regular_discount", 0)
        const calculatedCheapestListPrice = _getValue(assortmentProduct?.product, "calculatedCheapestPrice.listPrice.price", 0).value
        if (calculatedCheapestListPrice) {
          const calculatedCheapestListDiscount = Number(((calculatedCheapestListPrice * customerRegularDiscount) / 100).toFixed(CONSTANT.COMMON.FRACTION_DIGITS))
          unitPrice = calculatedCheapestListPrice - calculatedCheapestListDiscount
        } else if (calculatedPrice) {
          unitPrice = _getValue(calculatedPrice, "unitPrice", 0).value
        }
      }
      return Number(unitPrice.toFixed(CONSTANT.COMMON.FRACTION_DIGITS))
    },

    getUnitPrice(assortmentProduct: EecomTpAssortmentProduct, totalQuantites: number) {
      const unitPrice = this.getUnitPriceValue(assortmentProduct, totalQuantites)
      return formatPrice(unitPrice)
    },

    async setProductsInfo(assortmentProduct: EecomTpAssortmentProduct, totalQuantites: number, t: any) {
      if (!assortmentProduct) {
        return
      }
      this.assortmentProductsInfo.push({
        id: assortmentProduct.id,
        name: this.getProductName(assortmentProduct),
        media: this.getAssortmentProductMedia(assortmentProduct),
        mediaCover: this.getAssortmentProductMediaCover(assortmentProduct),
        productNumber: this.getProductNumber(assortmentProduct, t),
        mpn: this.getMPN(assortmentProduct, t),
        unitPriceText: this.getUnitPrice(assortmentProduct, totalQuantites),
      })
    },

    getListPrice(product: Product, totalQuantites: number) {
      const calculatedCheapestListPrice = _getValue(product, "calculatedCheapestPrice.listPrice", null).value
      const calculatedPrice = getCalculatedPriceObject(product, totalQuantites)
      let price: number = 0
      if (calculatedCheapestListPrice) {
        price = _getValue(calculatedCheapestListPrice, "price", 0).value
      } else if (calculatedPrice && calculatedPrice.listPrice) {
        price = _getValue(calculatedPrice.listPrice, "price", 0).value
      }
      return `${formatPrice(price)}*`
    },

    getProductAmount(assortmentProduct: EecomTpAssortmentProduct, totalQuantites: number) {
      const unitPrice = this.getUnitPriceValue(assortmentProduct, totalQuantites)
      return getMultiplicationOfTwoNumbersWithFractionDigits(unitPrice, totalQuantites, CONSTANT.COMMON.FRACTION_DIGITS)
    },

    isValidChildren(assortmentProduct: EecomTpAssortmentProduct, productQuantityValues: any, isAdidas: boolean) {
      const children = _getValue(assortmentProduct, "product.children", []).value
      if (!productQuantityValues || children?.length === 0 || !isAdidas) {
        return true
      }

      return children.every((child: any) => {
        const availableStock = child?.availableStock
        const childQuantity = productQuantityValues[child?.id]
        return !(child.isCloseout && availableStock > 0 && childQuantity > availableStock)
      })
    },

    checkValidConfig(assortmentProduct: EecomTpAssortmentProduct, configuration: any, productQuantityValues: any, isUserConsent: boolean) {
      let isValid = true
      const product = _getValue(assortmentProduct, "product", null).value
      if (!configuration) {
        return false
      }
      const configuredLineItems = configuration?.lineItem
      const customConfigurationArray = getCustomConfigurationArrayV2(productQuantityValues, assortmentProduct, product, configuredLineItems)
      customConfigurationArray.forEach((child: any) => {
        if (child.playerConfiguration) {
          for (let i = 0; i < child.playerConfiguration.quantity; i++) {
            if (child.mandatoryCustomization && child.mandatoryCustomization.length > 0) {
              for (let k = 0; k < child.mandatoryCustomization.length; k++) {
                const mc = child.mandatoryCustomization[k]
                const item = configuredLineItems[child.id]?.options?.[mc.id]?.values?.find((v: any) => v.amount === i + 1)
                if (mc.eecomTpCustomization.expertMode && isValid) {
                  const expertModeRegex = initRegExForExpertMode(mc.eecomTpCustomization.validationRule, "")
                  const itemValue = item ? item.value : null
                  if (isValidationErrorInExpertMode(itemValue, expertModeRegex) || (!isUserConsent && !itemValue)) {
                    isValid = false
                    break
                  }
                } else {
                  if (!item || (item && getValueLength(item.value) === 0)) {
                    isValid = false
                    return
                  }
                }
              }
            }
          }
        }
      })
      return isValid
    },
    checkValidPlayerConfig(lineItems: any, isUserConsent: boolean) {
      let isValid = true
      if (this.finalConfiguration && this.finalConfiguration.children && this.finalConfiguration.children.length > 0) {
        this.finalConfiguration.children.forEach((child: any) => {
          for (let i = 0; i < child?.playerConfiguration.quantity; i++) {
            if (child.mandatoryCustomization && child.mandatoryCustomization.length > 0) {
              for (const mc of child.mandatoryCustomization) {
                if (lineItems[child.id] && lineItems[child.id].options[mc.id] && lineItems[child.id].options[mc.id].values) {
                  for (let index = 0; index < lineItems[child.id].options[mc.id].values.length; index++) {
                    const item = lineItems[child.id].options[mc.id].values[index]
                    const _amount = i && i > 0 ? i + 1 : 1
                    if (item.amount === _amount) {
                      if (mc?.eecomTpCustomization?.expertMode && isValid) {
                        const expertModeRegex = initRegExForExpertMode(mc?.eecomTpCustomization?.validationRule, "")
                        const itemValue = lineItems[child.id].options[mc.id].values[index].value
                        if (isValidationErrorInExpertMode(itemValue, expertModeRegex) || (!isUserConsent && !itemValue)) {
                          isValid = false
                          break
                        }
                      } else if (getValueLength(lineItems[child.id].options[mc.id].values[index].value) === 0 && isValid) {
                        isValid = false
                        break
                      }
                    }
                  }
                }
              }
            }
            // for optional and expert mode
            if (child.optionalCustomization && child.optionalCustomization.length > 0) {
              for (const oc of child.optionalCustomization) {
                if (lineItems[child.id] && lineItems[child.id].options[oc.id] && lineItems[child.id].options[oc.id].values) {
                  for (let index = 0; index < lineItems[child.id].options[oc.id].values.length; index++) {
                    const item = lineItems[child.id].options[oc.id].values[index]
                    const _amount = i && i > 0 ? i + 1 : 1
                    if (item.amount === _amount) {
                      if (oc?.eecomTpCustomization?.expertMode && isValid) {
                        const expertModeRegex = initRegExForExpertMode(oc?.eecomTpCustomization?.validationRule, "")
                        const itemValue = lineItems[child.id].options[oc.id].values[index].value
                        if ((itemValue && isValidationErrorInExpertMode(itemValue, expertModeRegex)) || (!isUserConsent && !itemValue)) {
                          isValid = false
                          break
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        })
      }

      return isValid
    },

    async addOrUpdateLineItem(assortmentProduct: EecomTpAssortmentProduct, dbService: IndexedDbService, dbStoreName: string, productQuantityValues: any, isUserConsent: boolean, isConfigurable: boolean) {
      const id = _getValue(assortmentProduct, "id", CONSTANT.COMMON.FIELD_NULL).value
      const product: Product | null = get(assortmentProduct, "product", null)
      await dbService.connectWithIndexedDb()
      const assortmentItemObject = await dbService.get(dbStoreName, assortmentProduct?.id)
      const indexDBUpdatedAt = JSON.parse(_getValue(assortmentItemObject, "updatedAt", 0).value)
      const indexDBUpdatedAtTimestamp = new Date(indexDBUpdatedAt).getTime()
      const eecomTpAssortmentConfiguration = JSON.parse(_getValue(assortmentItemObject, "configuration", "{}").value)
      const configuredLineItems = _getValue(eecomTpAssortmentConfiguration, "items[0].lineItem", {}).value

      const customConfigurationArray = getCustomConfigurationArrayV2(productQuantityValues, assortmentProduct, product, configuredLineItems)
      const finalConfigurationArray = { children: customConfigurationArray }
      finalConfigurationArray.children.forEach((c: any) => {
        if (c.id && (!configuredLineItems[c.id] || productQuantityValues[c.id] !== configuredLineItems[c.id].quantity)) {
          configuredLineItems[c.id] = c.playerConfiguration
        }
      })

      const tempCartObj = prepareCartObject(assortmentProduct, isUserConsent, isConfigurable, configuredLineItems, finalConfigurationArray, false)
      const tempLineItems: Record<string, LineItem> = get(tempCartObj, "items[0].lineItem", {})
      if (tempLineItems && Object.keys(tempLineItems).length > 0) {
        Object.keys(tempLineItems).forEach((key: string) => {
          if (tempLineItems[key] && tempLineItems[key].quantity === 0) {
            delete tempLineItems[key]
          }
        })
      }

      if (Object.keys(tempLineItems).length > 0) {
        const obj = { id: id, configuration: JSON.stringify(tempCartObj), updatedAt: indexDBUpdatedAtTimestamp }
        await dbService.set(dbStoreName, obj)
      } else {
        await dbService.delete(dbStoreName, id)
      }
      IndexDBChanged.setIndexDBChangedSuccess(true)
    },

    restCustomizationTypeObject() {
      this.customizationTypeObject = {
        initials: {
          mandatoryCustomization: {},
          optionalCustomization: {},
        },
        playername: {
          mandatoryCustomization: {},
          optionalCustomization: {},
        },
        number: {
          mandatoryCustomization: {},
          optionalCustomization: {},
        },
        expertMode: {
          mandatoryCustomization: {},
          optionalCustomization: {},
        },
      }
    },

    setFinalConfigurations(assortmentProduct: EecomTpAssortmentProduct, currentAssortmentItemObject: any, productQuantityValues: any, t: any) {
      this.finalConfiguration = {} as FinalConfiguration
      const product = _getValue(assortmentProduct, "product", null).value
      const configuration = JSON.parse(_getValue(currentAssortmentItemObject, "configuration", "{}").value)
      const configuredLineItems = _getValue(configuration, "items[0].lineItem", {}).value

      const customizations = {
        mandatory: getUserInputCustomisation(get(assortmentProduct, "eecomTpMandatoryCustomizations", [])),
        optional: getUserInputCustomisation(get(assortmentProduct, "eecomTpOptionalCustomizations", [])),
        preFilled: getPreFilledInputCustomisation(get(assortmentProduct, "eecomTpMandatoryCustomizations", [])),
      }
      const customConfigurationArray = getCustomConfigurationArrayV2(productQuantityValues, assortmentProduct, product, configuredLineItems)

      // Set Headers
      const createHeaders = (product: any, customizations: any) => {
        let headers: FinalConfigurationHeader[] = []
        // TODO: Nedd to discuss with Dominik
        const isProductChild = !!(customConfigurationArray && customConfigurationArray.length === 1 && product.id === customConfigurationArray[0]?.id)

        if (!isProductChild) {
          headers = [
            {
              key: "product",
              label: t("eecom-teamportal.playerConfiguration.size"),
            },
          ]
        }

        Object.entries(customizations).forEach(([type, customisationList]: any) => {
          if (type !== "preFilled") {
            customisationList.forEach((c: any) => {
              const { customizationLabel } = getCustomizationNameFromOption(c.eecomTpCustomization, type === "mandatory", t)
              headers.push({ key: c.id, label: customizationLabel })
            })
          }
        })

        return headers
      }

      const headers = createHeaders(product, customizations)

      this.finalConfiguration = {
        id: assortmentProduct.id,
        headers: headers,
        children: customConfigurationArray,
      }

      this.restCustomizationTypeObject()
      this.handleCustomizationByType("mandatoryCustomization", configuredLineItems)
      this.handleCustomizationByType("optionalCustomization", configuredLineItems)
      return this.finalConfiguration
    },

    updateFinalConfigurations(newValue: any, customizationId: any, item: any, qIndex: number) {
      const optionValue = item.playerConfiguration.options[customizationId].values[qIndex]
      if (optionValue) {
        optionValue.value = newValue
      } else {
        item.playerConfiguration.options[customizationId].values.push({ amount: qIndex + 1, value: newValue })
      }

      const updatedChildren = this.finalConfiguration.children.map((child: any) => {
        if (child.id === item.id) {
          return { ...child, playerConfiguration: item.playerConfiguration }
        }
        return child
      })

      this.finalConfiguration = { ...this.finalConfiguration, children: updatedChildren }
    },

    parseFinishesFromConfiguration(assortmentProduct: EecomTpAssortmentProduct, currentContext: any) {
      const finishes: any = []

      if (!this.finalConfiguration) {
        return []
      }
      const taxState = _getValue(currentContext, "context.taxState", "").value
      const currencyId = _getValue(currentContext, "currency.id", "").value

      const processCustomizations = (customizations: any, customizationType: string, prefix: string) => {
        customizations.forEach(async (customization: any, index: number) => {
          const id = `${prefix}_${index}`
          const label = getCustomisationName(customization)
          let count = 0
          let price = 0
          let total = 0

          const c = customization.eecomTpCustomization
          if (!c.type || c.type !== CONSTANT.PLAYER_CONFIGURATIONS.TYPE.USER_INPUT) {
            count = this.getTotalPlayerCount(this.finalConfiguration)
          } else {
            const typeKey = this.getTypeKey(c.validationRule, customization)

            const customizationTypeKeys = Object.keys(this.customizationTypeObject[typeKey][customizationType])
            count = this.getFinishesCount(customizationTypeKeys, customization.id)
          }
          // TODO: if currency is different, getting NAN
          price = getOptionPrice(assortmentProduct, customization, count, taxState, currencyId)
          total = getTotalValue(count, price)

          finishes.push({ id, label, count, price, total })
        })
      }

      processCustomizations(assortmentProduct.eecomTpMandatoryCustomizations, "mandatoryCustomization", "finish_m")
      processCustomizations(assortmentProduct.eecomTpOptionalCustomizations, "optionalCustomization", "finish_o")
      const result = sortBy(finishes, ["label"])
      return result
    },

    getFinishesCount(customizationTypeKeys: any, customizationId: string) {
      let count = 0;
      if(customizationTypeKeys.length === 0){
        return 0
      }
      customizationTypeKeys.forEach((key: string) => {
        const parts = key.split('_');
        const cId = parts[3]; // index 3 is the customization id
        if(cId === customizationId){
          count++;
        }
      });

      return count;
    },

    calculateTotalFinishesPriceObject(finishes: any, t: any) {
      const finalObject: any = {
        label: "",
        total: 0,
        formatedTotal: "-",
      }

      let count = 0
      let totalPrice = 0
      finishes.forEach((finish: any) => {
        count += finish.count
        totalPrice = getSumOfTwoNumbersWithFractionDigits(finish.total, totalPrice, CONSTANT.COMMON.FRACTION_DIGITS)
      })
      finalObject.label = `${count} x ${t("eecom-teamportal.teamOrder.finishes")}`
      finalObject.total = totalPrice
      finalObject.formatedTotal = formatPrice(totalPrice)
      return finalObject
    },

    calculateSubTotal(assortmentProduct: EecomTpAssortmentProduct, totalFinishesPrice: any, totalQuantites: number) {
      return formatPrice(getSumOfTwoNumbersWithFractionDigits(this.getProductAmount(assortmentProduct, totalQuantites), totalFinishesPrice, CONSTANT.COMMON.FRACTION_DIGITS))
    },

    getTypeKey(validationRule: any, customization: any) {
      if (customization?.eecomTpCustomization?.expertMode) {
        return "expertMode"
      } else {
        switch (validationRule) {
          case CONSTANT.PLAYER_CONFIGURATIONS.REGEX.INITIALS:
            return "initials"
          case CONSTANT.PLAYER_CONFIGURATIONS.REGEX.PLAYER_NAME:
            return "playername"
          case CONSTANT.PLAYER_CONFIGURATIONS.REGEX.NUMBER:
            return "number"
          default:
            return "initials"
        }
      }
    },

    updateCustomizationValueObjectByType(child: any, customizationType: string, customization: any, value: any, index: number, amount: number) {
      const validationRule = customization?.eecomTpCustomization?.validationRule
      const typeKey = this.getTypeKey(validationRule, customization)
      const key = `ch_${child.id}_c_${customization.id}_i_${index}_a_${amount}`
      if (value) {
        this.customizationTypeObject[typeKey][customizationType][key] = value
      } else {
        delete this.customizationTypeObject[typeKey][customizationType][key]
      }
    },

    handleCustomizationByType(customizationType: any, lineItems: any) {
      if (!this.finalConfiguration) {
        return []
      }
      this.finalConfiguration?.children?.forEach((child: any) => {
        if (lineItems[child.id]) {
          for (let i = 0; i < lineItems[child.id]?.quantity; i++) {
            if (child[customizationType] && child[customizationType].length > 0) {
              child[customizationType].forEach((customization: any) => {
                if (lineItems[child.id] && lineItems[child.id].options[customization.id] && lineItems[child.id].options[customization.id].values) {
                  lineItems[child.id].options[customization.id].values.forEach((item: any, index: number) => {
                    const _amount = i > 0 ? i + 1 : 1
                    if (item.amount === _amount) {
                      this.updateCustomizationValueObjectByType(child, customizationType, customization, item.value, index, _amount)
                    }
                  })
                }
              })
            }
          }
        }
      })
    },

    calculateProductAmounts(assortmentProduct: any, currentAssortmentItemObject: any, totalQuantites: number, currentContext: any) {
      const productAmount = this.getProductAmount(assortmentProduct, totalQuantites)
      const calculateFinishesPriceInNumber = _calculateFinishesPriceInNumber(currentAssortmentItemObject, assortmentProduct, currentContext, totalQuantites)

      const calculateFinishesPrice = `+ ${formatPrice(calculateFinishesPriceInNumber)}*`
      const subTotal = formatPrice(getSumOfTwoNumbersWithFractionDigits(productAmount, calculateFinishesPriceInNumber, CONSTANT.COMMON.FRACTION_DIGITS))

      return {
        productAmount: `${formatPrice(productAmount)}*`,
        calculateFinishesPrice: calculateFinishesPrice,
        subTotal: subTotal,
      }
    },

    async updateConfigurationInIndexDB(assortmentProduct: EecomTpAssortmentProduct, lineItems: any, dbService: any, dbStoreName: string, isUserConsent: boolean) {
      const id = _getValue(assortmentProduct, "id", CONSTANT.COMMON.FIELD_NULL).value
      await dbService.connectWithIndexedDb()
      const assortmentItemObject = await dbService.get(dbStoreName, assortmentProduct?.id)
      const indexDBUpdatedAt = JSON.parse(_getValue(assortmentItemObject, "updatedAt", 0).value)
      const indexDBUpdatedAtTimestamp = new Date(indexDBUpdatedAt).getTime()

      const tempCartObj = prepareCartObject(assortmentProduct, isUserConsent, true, lineItems, this.finalConfiguration, false)
      const obj = { id: id, configuration: JSON.stringify(tempCartObj), updatedAt: indexDBUpdatedAtTimestamp }
      await dbService.set(dbStoreName, obj)
      IndexDBChanged.setIndexDBChangedSuccess(true)

      this.restCustomizationTypeObject()
      this.handleCustomizationByType("mandatoryCustomization", lineItems)
      this.handleCustomizationByType("optionalCustomization", lineItems)
    },

    async autoSavePlayerConfiguration(assortmentProduct: EecomTpAssortmentProduct, dbService: IndexedDbService, dbStoreName: string) {
      const assortmentProductId = _getValue(assortmentProduct, "id", null).value
      if (!assortmentProductId) {
        return null
      }
      const eecomTpAssortmentConfiguration = await this.fetchCurrentAssortmentProductConfiguration(assortmentProduct.id)
      const result = await dbService.get(dbStoreName, assortmentProductId)
      const indexDbSavedConfiguration = JSON.parse(_getValue(result, "configuration", "{}").value)

      if (!isEmpty(indexDbSavedConfiguration)) {
        if (indexDbSavedConfiguration && indexDbSavedConfiguration.items && indexDbSavedConfiguration.items.length > 0) {
          indexDbSavedConfiguration.items[0].lineItem = removeEmptyOptionalCustomizationV2(this.finalConfiguration, indexDbSavedConfiguration.items[0].lineItem, true)
        } else {
          await dbService.delete(dbStoreName, assortmentProductId)
        }
      }
      //configuration save logic in shopware db
      if (!isEmpty(indexDbSavedConfiguration) && !isEqual(eecomTpAssortmentConfiguration, indexDbSavedConfiguration)) {
        return await this.setConfiguration(assortmentProduct.id, false, { configuration: indexDbSavedConfiguration })
      }
      return null
    },

    getTotalPlayerCount(finalConfiguration: FinalConfiguration) {
      let total = 0
      if (!finalConfiguration || !finalConfiguration.children || finalConfiguration.children.length === 0) {
        return 0
      }
      finalConfiguration.children.forEach((child: any) => {
        total += +child.playerConfiguration.quantity
      })
      return total
    },

    async fetchCurrentAssortmentProductConfiguration(eecomTpAssortmentProductId: string) {
      let eecomTpAssortmentConfiguration = {}
      const customerStore = useCustomerStore()

      const customerId = _getValue(customerStore.customer, "id", "").value
      if (customerId) {
        const reqBodyParams = {
          totalCountMode: CONSTANT.BODY_PARAMS.TOTAL_COUNT_MODE_DEFAULT,
          limit: CONSTANT.COMMON.DEFAULT_PAGE_SIZE,
          page: 1,
          associations: CONSTANT.BODY_PARAMS.ASSOCIATIONS.ASSOCIATIONS_ASSORTMENT_PRODUCTS_BY_CUSTOMER_ID,
          sort: [
            {
              field: "eecomTpAssortmentProducts.eecomTpAssortmentProductMedia.position",
              order: "ASC",
              naturalSorting: true,
            },
            {
              field: "name",
              order: "ASC",
              naturalSorting: false,
            },
          ],
          filter: [
            {
              type: "equals",
              field: "active",
              value: true,
            },
            {
              type: "equals",
              field: "eecomTpAssortmentProducts.id",
              value: eecomTpAssortmentProductId,
            },
          ],
        }
        const productRangeStore = useProductRangeStore()
        const result = (await productRangeStore.fetchAssortmentDetailsByCustomerId(customerId, reqBodyParams, false)) as ApiResponse
        if (result && (result.status === CONSTANT.HTTP_STATUS.OK || result.status === CONSTANT.HTTP_STATUS.NO_CONTENT)) {
          const productsArray = _getValue(result, "data.elements[0].eecomTpAssortmentProducts").value || [];
          let tempEecomTpAssortmentProduct = {};
          if (productsArray && productsArray.length){
            tempEecomTpAssortmentProduct = productsArray.find((product: any) => product.id === eecomTpAssortmentProductId)
          }
          eecomTpAssortmentConfiguration = _getValue(
            tempEecomTpAssortmentProduct,
            `eecomTpAssortmentConfiguration[${get(tempEecomTpAssortmentProduct, "eecomTpAssortmentConfiguration.length", 1) - 1}].configuration`,
            {}
          ).value
        }
      }
      return eecomTpAssortmentConfiguration
    },

    createFileName(eecomAssortmentName: string, productName: string) {
      const timestamp = new Date().getTime()
      return `${eecomAssortmentName}_${productName}_${timestamp}.csv`
    },

    extractCustomizationsData(lineItems: any, t: any) {
      return Object.keys(lineItems).flatMap((key) => {
        const child = this.finalConfiguration?.children.find((child: any) => child.id === key)
        const options = lineItems[key]?.options

        if (!child || !options) return []

        const userInputOptions = Object.entries(options).filter(([, option]: any) => option.type === "user-input")

        return userInputOptions
          .map(([optionKey, { values }]: any) => {
            const allCustomisations = [...child.mandatoryCustomization, ...child.optionalCustomization]
            const c = allCustomisations.find((option) => option.id === optionKey)
            const { customizationType } = getCustomizationNameFromOption(c.eecomTpCustomization, false, t)

            const customizationValues = values.reduce((acc: { [x: string]: any }, { amount, value }: any) => {
              acc[amount] = value
              return acc
            }, {})

            return Object.keys(customizationValues).map((amount) => ({
              [customizationType]: customizationValues[amount],
            }))
          })
          .flat()
      })
    },

    convertArrayOfObjectsToArray(arr: any[], keys: string[]) {
      return arr.map((obj: any) => keys.map((key: any) => obj[key] ?? ""))
    },

    // Function to convert an array of arrays into a CSV string
    convertToCSV(rows: string[][], t: any, title?: string) {
      // Create header row using first element of rows array
      rows.unshift([
        t("eecom-teamportal.playerConfiguration.playerName"),
        t("eecom-teamportal.playerConfiguration.number"),
        t("eecom-teamportal.playerConfiguration.initials"),
        t("eecom-teamportal.playerConfiguration.expertMode"),
      ])
      // Join remaining rows with comma separator and add new line character
      let csvContent = rows.map((row) => row.join(",")).join("\n")
      // Add the table title to the CSV content if provided
      if (title) {
        csvContent = `Title: ${title}\n${csvContent}`
      }
      return csvContent
    },

    async onDownload(assortmentProduct: EecomTpAssortmentProduct, dbService: any, dbStoreName: string, t: any) {
      const productRangeStore = useProductRangeStore()
      const elements = _getValue(productRangeStore.assortmentDetails, "elements", []).value
      const eecomTpAssortmentId = _getValue(assortmentProduct, "eecomTpAssortmentId", null).value
      let eecomAssortmentName = ""
      if (elements.length !== 0 && eecomTpAssortmentId) {
        const selectedCollection = elements.find((item: any) => item.id === eecomTpAssortmentId)
        eecomAssortmentName = _getValue(selectedCollection, "name", get(selectedCollection, "translated.name", "")).value
      }
      const productsInfo = this.assortmentProductsInfo?.find((item: any) => item?.id === assortmentProduct?.id)
      const fileName = this.createFileName(eecomAssortmentName, productsInfo.name)
      const assortmentItemObject = await dbService.get(dbStoreName, assortmentProduct?.id)
      const configuration = parseConfigurationFromItemObject(assortmentItemObject)
      const lineItems = configuration?.lineItem

      const resultArr = this.extractCustomizationsData(lineItems, t)
      const rows: string[][] = this.convertArrayOfObjectsToArray(resultArr, ["playerName", "number", "initials", "expertMode"])
      const csvContent = this.convertToCSV(rows, t)
      downloadCSV(fileName, csvContent)
    },
    async addToCart(bodyParams: AddToCart) {
      try {
        return await httpService.post(CONSTANT.API.TEAM_ORDER.ADD_TO_CART, bodyParams)
      } catch (error) {
        return get(error, `response.data.errors`, [])
      }
    },
    async checkoutCart() {
      try {
        const result: ApiResponse = await httpService.get(CONSTANT.API.TEAM_ORDER.CHECKOUT_CART)
        if (result && (result.status === CONSTANT.HTTP_STATUS.OK || result.status === CONSTANT.HTTP_STATUS.CREATED)) {
          this.checkoutCartObj = get(result, "data", null) as Cart
          this.orderReference = _getValue(this.checkoutCartObj, "extensions.orderReference.orderReference", "").value
        }
      } catch (error) {
        const errors = get(error, `response.data.errors`, [])
        console.error(errors)
      }
    },
    async removeCartItem(bodyParams: any) {
      try {
        return await httpService.delete(CONSTANT.API.TEAM_ORDER.REMOVE_FROM_CART, bodyParams)
      } catch (error) {
        return get(error, `response.data.errors`, [])
      }
    },
    async setConfiguration(assortmentProductId: any, isLoader: boolean = true, bodyParams: any) {
      try {
        const setConfigurationUrl = CONSTANT.API.TEAM_ORDER.SET_CONFIGURATION.replace("{assortmentProductId}", assortmentProductId)
        let result: any
        if (isLoader) {
          result = await httpService.post(setConfigurationUrl, bodyParams)
        } else {
          result = await httpServiceWithoutLoader.post(setConfigurationUrl, bodyParams)
        }
        return result
      } catch (error) {
        return get(error, `response.data.errors`, [])
      }
    },
    async setOrderReference(value: any) {
      const bodyParams = {
        orderReference: value,
      }
      try {
        return await httpService.post(CONSTANT.API.TEAM_ORDER.SET_ORDER_REFERENCE, bodyParams)
      } catch (error) {
        return get(error, `response.data.errors`, [])
      }
    },
  },
})

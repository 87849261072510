import { defineStore } from "pinia"
import { HttpService } from "@/helpers/http-service"
import { CONSTANT } from "@/helpers/constant"
import _, {get, isEmpty, isEqual, isString, sortBy} from "lodash"
import { ApiResponse } from "@/types/apiResponse"
import {FinalConfiguration, FinalConfigurationHeader} from "@/types/teamOrder";
import {EecomTpAssortmentProduct} from "@/types/eecomTpAssortmentProduct";
import {
  _getValue, downloadCSV,
  getCalculatedPriceObject, getCustomConfigurationArrayV3,
  getCustomisationName,
  getCustomizationNameFromOption,
  getMultiplicationOfTwoNumbersWithFractionDigits,
  getOptionPrice,
  getPreFilledInputCustomisation, getSumOfTwoNumbersWithFractionDigits, getTotalValue,
  getUserInputCustomisation,
  getValueLength,
  initRegExForExpertMode,
  isValidationErrorInExpertMode, parseConfigurationFromItemObject,
  prepareCartObject, prepareCartObjectInOffer, removeEmptyOptionalCustomizationV2
} from "@/helpers/common";
import {
  _calculateFinishesPriceInNumber,
  getAssortmentProductMediaCoverUrl,
  getAssortmentProductMediaUrl
} from "@/helpers/assortment-item-service";
import {useCustomerStore} from "@/stores/customer.store";
import {useSystemAndContextStore} from "@/stores/systemAndContext.store";
import formatPrice from "@/helpers/format-price";
import {Product} from "@/types/product";
import {IndexedDbService} from "@/helpers/indexed-db-service";
import {AddToCart, LineItem} from "@/models/addToCart.model";
import {IndexDBChanged} from "@/helpers/behavior-service";
import {useProductRangeStore} from "@/stores/productRange.store";
import {Cart} from "@/types/cart/cart";
import {Context} from "@/types/systemContext";
import {computed} from "vue";
import {useTeamOrderStore} from "@/stores/teamOrder.store";

const httpService = new HttpService()
const httpServiceWithoutLoader = new HttpService(false)

interface State {
  offers: any
  offersResult: any
  offerAssortmentProducts: any,
  assortmentProductsInfo: any
  finalConfiguration: FinalConfiguration
  checkoutCartObj: any
  customizationTypeObject: any
  changedOfferRangeId: ''
  orderReference: string
}
export const useOffersStore = defineStore({
  id: "offer",
  state: (): State => ({
    offers: [],
    offersResult: {},
    offerAssortmentProducts: [],
    assortmentProductsInfo: [],
    finalConfiguration: {} as FinalConfiguration,
    checkoutCartObj: {},
    changedOfferRangeId: '',
    customizationTypeObject: {
      initials: {
        mandatoryCustomization: {},
        optionalCustomization: {},
      },
      playername: {
        mandatoryCustomization: {},
        optionalCustomization: {},
      },
      number: {
        mandatoryCustomization: {},
        optionalCustomization: {},
      },
      expertMode: {
        mandatoryCustomization: {},
        optionalCustomization: {},
      },
    },
    orderReference: "",
  }),
  actions: {
    async fetch(customerId: any, bodyParams: any = {}) {

      if ( !customerId || !isString(customerId) ){
        console.log('customerId is required');
        return false
      }

      try {
        const fetchSalesRepCustomerOfferUrl = CONSTANT.API.OFFER.FETCH_OFFERS.replace("{customerId}", customerId)
        const result: ApiResponse = await httpService.post(fetchSalesRepCustomerOfferUrl, bodyParams.value)
        if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
          const offers = _.get(result, "data.elements", [])
          const offersResult = _.get(result, "data", null)
          if (offersResult) {
            this.offersResult = offersResult;
            this.offers = offers;
          }
          return true;
        }
      } catch (e) { console.log(e);}
      this.offersResult = {};
      this.offers = [];
      return false;
    },
    async fetchCustomerOffer(customerId: any, bodyParams: any = {}) {

      if ( !customerId || !isString(customerId) ){
        console.log('customerId is required');
        return false
      }

      try {
        const fetchSalesRepCustomerOfferUrl = CONSTANT.API.OFFER.FETCH_CUSTOMER_OFFERS.replace("{customerId}", customerId)
        const result: ApiResponse = await httpService.get(fetchSalesRepCustomerOfferUrl, bodyParams.value)
        if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
          const offers = _.get(result, "data.elements", [])
          const offersResult = _.get(result, "data", null)
          if (offersResult) {
            this.offersResult = offersResult;
            this.offers = offers;
          }
          return true;
        }
      } catch (e) { console.log(e);}
      this.offersResult = {};
      this.offers = [];
      return false;
    },
    async save(customerId: string, offer: any) {
      const url = CONSTANT.API.OFFER.UPSERT_OFFERS.replace("{customerId}", customerId)
      try {
         const result: ApiResponse = await httpService.post(url, offer)
         if (result && result.status === CONSTANT.HTTP_STATUS.NO_CONTENT) {
           return true;
         }
        return offer;
      } catch (e) {
        return false;
      }
    },

    // async updateOLD(customerId: string, offerId: string, bodyParams: any) {
    //   try {
    //     const updateOfferStatusUrl = CONSTANT.API.OFFER.UPDATE_OFFERS.replace("{id}", offerId).replace("{customerId}", customerId)
    //     const result: ApiResponse = await httpService.patch(updateOfferStatusUrl, bodyParams)
    //     if (result.status === CONSTANT.HTTP_STATUS.OK || result.status === CONSTANT.HTTP_STATUS.NO_CONTENT) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   } catch (error) {
    //     return false;
    //   }
    // },

    async update(customerId: string, offerId: string, bodyParams: any) {
      try {
        const updateOfferStatusUrl = CONSTANT.API.OFFER.UPDATE_OFFERS.replace("{id}", offerId).replace("{customerId}", customerId)
        const result: ApiResponse = await httpService.patch(updateOfferStatusUrl, bodyParams)
        if (result.status === CONSTANT.HTTP_STATUS.OK || result.status === CONSTANT.HTTP_STATUS.NO_CONTENT) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    },
    async delete(customerId: string, offerId: string) {
      try {
        const deleteOfferUrl = CONSTANT.API.OFFER.DELETE_OFFERS.replace("{id}", offerId).replace("{customerId}", customerId)
        const result: ApiResponse = await httpService.delete(deleteOfferUrl)
        if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
          return false;
      }
    },

    async fetchOfferProducts(customerId: string, offerId: string,  bodyParams: any) {
      try {
        const fetchCustomerAssortmentProductDetailsByAssortmentIdURL = CONSTANT.API.OFFER.FETCH_OFFER_ASSORTMENT_PRODUCTS.replace("{id}", offerId).replace("{customerId}", customerId)
        let result: any
        result = await httpService.post(fetchCustomerAssortmentProductDetailsByAssortmentIdURL, bodyParams.value)
        if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
          (this.offerAssortmentProducts as any) = _.get(result, "data.elements", [])
        } else {
          (this.offerAssortmentProducts as any) = []
        }
      } catch (error) {
        const errors = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          // @ts-ignore
          console.error(errors[0].detail)
        }
        console.error(error)
      }
    },
    async fetchCustomerOfferProducts(customerId: string, offerId: string,  bodyParams: any) {
      try {
        const fetchCustomerAssortmentProductDetailsByAssortmentIdURL = CONSTANT.API.OFFER.FETCH_CUSTOMER_OFFERS_DETAILS.replace("{id}", offerId).replace("{customerId}", customerId)
        let result: any
        result = await httpService.post(fetchCustomerAssortmentProductDetailsByAssortmentIdURL, bodyParams)


        if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
          (this.offerAssortmentProducts as any) = _.get(result, "data", [])
        } else {
          (this.offerAssortmentProducts as any) = []
        }

      } catch (error) {
        const errors = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          // @ts-ignore
          console.error(errors[0].detail)
        }
        console.error(error)
      }
    },

    getProductName(assortmentProduct: EecomTpAssortmentProduct) {
      return _getValue(assortmentProduct, "eecomTpAssortmentProduct.product.name", _getValue(assortmentProduct, "eecomTpAssortmentProduct.product.translated.name", CONSTANT.COMMON.FIELD_NULL).value).value
    },

    getProductNumber(assortmentProduct: EecomTpAssortmentProduct, t: any) {
      const productNumber = _getValue(assortmentProduct, "eecomTpAssortmentProduct.product.productNumber", CONSTANT.COMMON.FIELD_NULL).value
      const productNumberText = t("eecom-teamportal.sales-force.product-range.product-number")
      return productNumberText.replace(new RegExp("%ARTICLE_NO%", "g"), productNumber)
    },

    getMPN(assortmentProduct: EecomTpAssortmentProduct, t: any) {
      const mpn = _getValue(assortmentProduct, "eecomTpAssortmentProduct.product.manufacturerNumber", CONSTANT.COMMON.FIELD_NULL).value
      const mpnText = t("eecom-teamportal.sales-force.product-range.mpn")
      return mpnText.replace(new RegExp("%MPN_VALUE%", "g"), mpn)
    },

    getAssortmentProductMedia(assortmentProduct: EecomTpAssortmentProduct) {
      const data = sortBy(assortmentProduct?.eecomTpAssortmentProduct?.eecomTpAssortmentProductMedia, function (o) {
        return !o.isCover
      })

      const images = data?.map((item: any) => ({
        id: item.id,
        path: getAssortmentProductMediaUrl(item),
      }))
      return images
    },

    getAssortmentProductMediaCover(assortmentProduct: EecomTpAssortmentProduct) {
      const images = [
        {
          id: assortmentProduct?.id,
          path: getAssortmentProductMediaCoverUrl(assortmentProduct?.eecomTpAssortmentProduct),
        },
      ]
      return images
    },

    getUnitPriceValue(assortmentProduct: EecomTpAssortmentProduct, totalQuantites: number) {
      const customerStore = useCustomerStore()
      const systemAndContextStore = useSystemAndContextStore()

      const calculatedPrice = getCalculatedPriceObject(assortmentProduct?.eecomTpAssortmentProduct?.product, totalQuantites)
      const assortmentProductPrice = _getValue(assortmentProduct, "calculatedPrice.unitPrice", null).value
      const customer = customerStore.customerData
      const displayGross = _getValue(customer, "group.displayGross", false).value
      let unitPrice: number = 0
      if (assortmentProductPrice) {
        unitPrice = assortmentProductPrice;
      } else {
        const customerRegularDiscount = get(customer, "customFields.eecom_tp_customer_regular_discount", 0)
        const calculatedCheapestListPrice = _getValue(assortmentProduct?.eecomTpAssortmentProduct?.product, "calculatedCheapestPrice.listPrice.price", 0).value
        if (calculatedCheapestListPrice) {
          const calculatedCheapestListDiscount = Number(((calculatedCheapestListPrice * customerRegularDiscount) / 100).toFixed(CONSTANT.COMMON.FRACTION_DIGITS))
          unitPrice = calculatedCheapestListPrice - calculatedCheapestListDiscount
        } else if (calculatedPrice) {
          unitPrice = _getValue(calculatedPrice, "unitPrice", 0).value
        }
      }
      return Number(unitPrice.toFixed(CONSTANT.COMMON.FRACTION_DIGITS))
    },

    getUnitPrice(assortmentProduct: EecomTpAssortmentProduct, totalQuantites: number) {
      const unitPrice = this.getUnitPriceValue(assortmentProduct, totalQuantites)
      return formatPrice(unitPrice)
    },

    async setProductsInfo(assortmentProduct: EecomTpAssortmentProduct, totalQuantites: number, t: any) {
      if (!assortmentProduct) {
        return
      }
      this.assortmentProductsInfo.push({
        id: assortmentProduct.id,
        name: this.getProductName(assortmentProduct),
        media: this.getAssortmentProductMedia(assortmentProduct),
        mediaCover: this.getAssortmentProductMediaCover(assortmentProduct),
        productNumber: this.getProductNumber(assortmentProduct, t),
        mpn: this.getMPN(assortmentProduct, t),
        unitPriceText: this.getUnitPrice(assortmentProduct, totalQuantites),
      })
    },

    getListPrice(product: Product, totalQuantites: number) {
      const calculatedCheapestListPrice = _getValue(product, "calculatedCheapestPrice.listPrice", null).value
      const calculatedPrice = getCalculatedPriceObject(product, totalQuantites)
      let price: number = 0
      if (calculatedCheapestListPrice) {
        price = _getValue(calculatedCheapestListPrice, "price", 0).value
      } else if (calculatedPrice && calculatedPrice.listPrice) {
        price = _getValue(calculatedPrice.listPrice, "price", 0).value
      }
      return `${formatPrice(price)}*`
    },

    getProductAmount(assortmentProduct: EecomTpAssortmentProduct, totalQuantites: number) {
      const unitPrice = this.getUnitPriceValue(assortmentProduct, totalQuantites)
      return getMultiplicationOfTwoNumbersWithFractionDigits(unitPrice, totalQuantites, CONSTANT.COMMON.FRACTION_DIGITS)
    },

    isValidChildren(assortmentProduct: EecomTpAssortmentProduct, productQuantityValues: any) {
      const children = _getValue(assortmentProduct, "eecomTpAssortmentProduct.product.children", []).value
      if (!productQuantityValues || children?.length === 0) {
        return true
      }

      return children.every((child: any) => {
        const availableStock = child?.availableStock
        const childQuantity = productQuantityValues[child?.id]
        return !(child.isCloseout && availableStock > 0 && childQuantity > availableStock)
      })
    },

    checkValidConfig(assortmentProduct: EecomTpAssortmentProduct, configuration: any, productQuantityValues: any, optionalOptionQuantityValues: any, isUserConsent: boolean) {
      let isValid = true
      const product = _getValue(assortmentProduct, "eecomTpAssortmentProduct.product", null).value
      if (!configuration) {
        return false
      }
      const configuredLineItems = configuration?.lineItem
      const customConfigurationArray = getCustomConfigurationArrayV3(optionalOptionQuantityValues, productQuantityValues, assortmentProduct, product, configuredLineItems)
      customConfigurationArray.forEach((child: any) => {
        if (child.playerConfiguration) {
          for (let i = 0; i < child.playerConfiguration.quantity; i++) {
            if (child.mandatoryCustomization && child.mandatoryCustomization.length > 0) {
              for (let k = 0; k < child.mandatoryCustomization.length; k++) {
                const mc = child.mandatoryCustomization[k]
                const item = configuredLineItems[child.id]?.options?.[mc.id]?.values?.find((v: any) => v.amount === i + 1)
                if (mc.eecomTpCustomization.expertMode && isValid) {
                  const expertModeRegex = initRegExForExpertMode(mc.eecomTpCustomization.validationRule, "")
                  const itemValue = item ? item.value : null
                  if (isValidationErrorInExpertMode(itemValue, expertModeRegex) || (!isUserConsent && !itemValue)) {
                    isValid = false
                    break
                  }
                } else {
                  if (!item || (item && getValueLength(item.value) === 0)) {
                    isValid = false
                    return
                  }
                }
              }
            }
          }
        }
      })
      return isValid
    },

    checkValidPlayerConfig(lineItems: any, isUserConsent: boolean) {
      let isValid = true
      if (this.finalConfiguration && this.finalConfiguration.children && this.finalConfiguration.children.length > 0) {
        this.finalConfiguration.children.forEach((child: any) => {
          for (let i = 0; i < child?.playerConfiguration.quantity; i++) {
            if (child.mandatoryCustomization && child.mandatoryCustomization.length > 0) {
              for (const mc of child.mandatoryCustomization) {
                if (lineItems[child.id] && lineItems[child.id].options[mc.id] && lineItems[child.id].options[mc.id].values) {
                  for (let index = 0; index < lineItems[child.id].options[mc.id].values.length; index++) {
                    const item = lineItems[child.id].options[mc.id].values[index]
                    const _amount = i && i > 0 ? i + 1 : 1
                    if (item.amount === _amount) {
                      if (mc?.eecomTpCustomization?.expertMode && isValid) {
                        const expertModeRegex = initRegExForExpertMode(mc?.eecomTpCustomization?.validationRule, "")
                        const itemValue = lineItems[child.id].options[mc.id].values[index].value
                        if (isValidationErrorInExpertMode(itemValue, expertModeRegex) || (!isUserConsent && !itemValue)) {
                          isValid = false
                          break
                        }
                      } else if (getValueLength(lineItems[child.id].options[mc.id].values[index].value) === 0 && isValid) {
                        isValid = false
                        break
                      }
                    }
                  }
                }
              }
            }
            // for optional and expert mode
            // if (child.optionalCustomization && child.optionalCustomization.length > 0) {
            //   for (const oc of child.optionalCustomization) {
            //     if (lineItems[child.id] && lineItems[child.id].options[oc.id] && lineItems[child.id].options[oc.id].values) {
            //       for (let index = 0; index < lineItems[child.id].options[oc.id].values.length; index++) {
            //         const item = lineItems[child.id].options[oc.id].values[index]
            //         const _amount = i && i > 0 ? i + 1 : 1
            //         if (item.amount === _amount) {
            //           if (oc?.eecomTpCustomization?.expertMode && isValid) {
            //             const expertModeRegex = initRegExForExpertMode(oc?.eecomTpCustomization?.validationRule, "")
            //             const itemValue = lineItems[child.id].options[oc.id].values[index].value
            //             if ((itemValue && isValidationErrorInExpertMode(itemValue, expertModeRegex)) || (!isUserConsent && !itemValue)) {
            //               isValid = false
            //               break
            //             }
            //           }
            //         }
            //       }
            //     }
            //   }
            // }
          }
        })
      }

      return isValid
    },

    restCustomizationTypeObject() {
      this.customizationTypeObject = {
        initials: {
          mandatoryCustomization: {},
          optionalCustomization: {},
        },
        playername: {
          mandatoryCustomization: {},
          optionalCustomization: {},
        },
        number: {
          mandatoryCustomization: {},
          optionalCustomization: {},
        },
        expertMode: {
          mandatoryCustomization: {},
          optionalCustomization: {},
        },
      }
    },
    async addOrUpdateLineItem(assortmentProduct: EecomTpAssortmentProduct, dbService: IndexedDbService, dbStoreName: string, productQuantityValues: any, optionalOptionQuantityValues: any, isUserConsent: boolean, isConfigurable: boolean) {
      const id = _getValue(assortmentProduct, "id", CONSTANT.COMMON.FIELD_NULL).value
      const product: Product | null = get(assortmentProduct, "eecomTpAssortmentProduct.product", null)
      await dbService.connectWithIndexedDb()
      const assortmentItemObject = await dbService.get(dbStoreName, assortmentProduct.id)
      const indexDBUpdatedAt = JSON.parse(_getValue(assortmentItemObject, "updatedAt", 0).value)
      const indexDBUpdatedAtTimestamp = new Date(indexDBUpdatedAt).getTime()
      const eecomTpAssortmentConfiguration = JSON.parse(_getValue(assortmentItemObject, "configuration", "{}").value)
      const configuredLineItems = _getValue(eecomTpAssortmentConfiguration, "items[0].lineItem", {}).value
      const customConfigurationArray = getCustomConfigurationArrayV3(optionalOptionQuantityValues, productQuantityValues, assortmentProduct, product, configuredLineItems)
      const finalConfigurationArray = {children: customConfigurationArray}
      finalConfigurationArray.children.forEach((c: any) => {
        if (c.id) {
          configuredLineItems[c.id] = c.playerConfiguration
        }
      })

      const tempCartObj = prepareCartObject(assortmentProduct, isUserConsent, isConfigurable, configuredLineItems, finalConfigurationArray, false)
      const tempLineItems: Record<string, LineItem> = get(tempCartObj, "items[0].lineItem", {})
      if (tempLineItems && Object.keys(tempLineItems).length > 0) {
        Object.keys(tempLineItems).forEach((key: string) => {
          if (tempLineItems[key] && tempLineItems[key].quantity === 0) {
            delete tempLineItems[key]
          }
        })
      }

      if (Object.keys(tempLineItems).length > 0) {
        const obj = {id: id, configuration: JSON.stringify(tempCartObj), updatedAt: indexDBUpdatedAtTimestamp}
        await dbService.set(dbStoreName, obj)
      } else {
        await dbService.delete(dbStoreName, id)
      }
      IndexDBChanged.setIndexDBChangedSuccess(true)
    },
    setFinalConfigurations(assortmentProduct: EecomTpAssortmentProduct, currentAssortmentItemObject: any, productQuantityValues: any, optionalOptionQuantityValues: any, t: any) {
      this.finalConfiguration = {} as FinalConfiguration
      const product = _getValue(assortmentProduct, "eecomTpAssortmentProduct.product", null).value
      const configuration = JSON.parse(_getValue(currentAssortmentItemObject, "configuration", "{}").value)
      const configuredLineItems = _getValue(configuration, "items[0].lineItem", {}).value
      const customizations = {
        mandatory: getUserInputCustomisation(get(assortmentProduct, "eecomTpOfferMandatoryOptions", [])),
        optional: getUserInputCustomisation(get(assortmentProduct, "eecomTpOfferOptionalOptions", [])),
        preFilled: getPreFilledInputCustomisation(get(assortmentProduct, "eecomTpOfferMandatoryOptions", [])),
      }
      const customConfigurationArray = getCustomConfigurationArrayV3(optionalOptionQuantityValues, productQuantityValues, assortmentProduct, product, configuredLineItems)

      // Set Headers
      const createHeaders = (product: any, customizations: any) => {
        let headers: FinalConfigurationHeader[] = []
        // TODO: Nedd to discuss with Dominik
        const isProductChild = !!(customConfigurationArray && customConfigurationArray.length === 1 && product.id === customConfigurationArray[0]?.id)

        if (!isProductChild) {
          headers = [
            {
              key: "product",
              label: t("eecom-teamportal.playerConfiguration.size"),
            },
          ]
        }

        Object.entries(customizations).forEach(([type, customisationList]: any) => {
          if (type !== "preFilled") {
            customisationList.forEach((c: any) => {
              // Check if the customization optionQty is greater than 0 in any of the customConfigurationArray objects
              let hasValidOptionQty = false;
              if (type === "optional") {
                hasValidOptionQty = _.cloneDeep(customConfigurationArray).some((config: any) =>
                  config.optionalCustomization?.some(
                    (option: any) => option.id === c.id && option.optionQty > 0
                  )
                );
              }
              if (hasValidOptionQty) {
                  const {customizationLabel} = getCustomizationNameFromOption(
                    c.eecomTpCustomization,
                    false,
                    t
                  );
                  headers.push({key: c.id, label: customizationLabel});

              } else {
                const {customizationLabel} = getCustomizationNameFromOption(
                  c.eecomTpCustomization,
                  type === "mandatory",
                  t
                );
                headers.push({key: c.id, label: customizationLabel});
              }

              // const {customizationLabel} = getCustomizationNameFromOption(c.eecomTpCustomization, type === "mandatory", t)
              // headers.push({key: c.id, label: customizationLabel})
            })
          }
        })

        return headers
      }

      const headers = createHeaders(product, customizations)

      this.finalConfiguration = {
        id: assortmentProduct.id,
        headers: headers,
        children: customConfigurationArray,
      }

      this.restCustomizationTypeObject()
      this.handleCustomizationByType("mandatoryCustomization", configuredLineItems)
      this.handleCustomizationByType("optionalCustomization", configuredLineItems)
      return this.finalConfiguration
    },

    updateFinalConfigurations(newValue: any, customizationId: any, item: any, qIndex: number) {
      const optionValue = item.playerConfiguration.options[customizationId].values[qIndex]
      if (optionValue) {
        optionValue.value = newValue
      } else {
        item.playerConfiguration.options[customizationId].values.push({amount: qIndex + 1, value: newValue})
      }

      const updatedChildren = this.finalConfiguration.children.map((child: any) => {
        if (child.id === item.id) {
          return {...child, playerConfiguration: item.playerConfiguration}
        }
        return child
      })

      this.finalConfiguration = {...this.finalConfiguration, children: updatedChildren}
    },

    // Helper function to aggregate quantities from all children
    // Function to aggregate total quantities
    aggregateQuantities(configs: any, type: 'optional' | 'mandatory'): Record<number, number> {
      const totalQuantities = new Map<number, number>();

      configs.forEach((config: any) => {
        const configList = type === 'optional' ? config.eecomTpOfferOptionalConfig : config.eecomTpOfferMandatoryConfig;
        configList.forEach((item: any) => {
          const key = type === 'optional' ? item.eecomTpOfferOptionalOptionId : item.eecomTpOfferMandatoryOptionId;
          totalQuantities.set(key, (totalQuantities.get(key) || 0) + item.quantity);
        });
      });

      return Object.fromEntries(totalQuantities);
    },
    parseFinishesFromConfiguration(assortmentProduct: EecomTpAssortmentProduct, currentContext: any) {
      const finishes: any = []

      if (!this.finalConfiguration) {
        return []
      }
      const taxState = _getValue(currentContext, "context.taxState", "").value
      const currencyId = _getValue(currentContext, "currency.id", "").value

      const processCustomizations = (customizations: any, customizationType: string, prefix: string) => {
        customizations.forEach(async (customization: any, index: number) => {
          const id = `${prefix}_${index}`
          const label = getCustomisationName(customization)
          let count = 0
          let price = 0
          let total = 0

          const c = customization.eecomTpCustomization
          if (!c.type || c.type !== CONSTANT.PLAYER_CONFIGURATIONS.TYPE.USER_INPUT) {
            count = this.getTotalPlayerCount(this.finalConfiguration)
          } else {
            const typeKey = this.getTypeKey(c.validationRule, customization)
            const keys = Object.keys(this.customizationTypeObject[typeKey][customizationType])
            const uniqueKeys = keys.filter(key => key.includes(`_c_${customization.id}_`))
            count = uniqueKeys.length
          }

          price = getOptionPrice(assortmentProduct, customization, count, taxState, currencyId)
          total = getTotalValue(count, price)

          finishes.push({id, label, count, price, total})
        })
      }

      processCustomizations(assortmentProduct.eecomTpOfferMandatoryOptions, "mandatoryCustomization", "finish_m")
      processCustomizations(assortmentProduct.eecomTpOfferOptionalOptions, "optionalCustomization", "finish_o")
      const result = sortBy(finishes, ["label"])
      return result
    },

    calculateTotalFinishesPriceObject(finishes: any, t: any) {
      const finalObject: any = {
        label: "",
        total: 0,
        formatedTotal: "-",
      }

      let count = 0
      let totalPrice = 0
      finishes.forEach((finish: any) => {
        count += finish.count
        totalPrice = getSumOfTwoNumbersWithFractionDigits(finish.total, totalPrice, CONSTANT.COMMON.FRACTION_DIGITS)
      })
      finalObject.label = `${count} x ${t("eecom-teamportal.teamOrder.finishes")}`
      finalObject.total = totalPrice
      finalObject.formatedTotal = formatPrice(totalPrice)
      return finalObject
    },

    calculateSubTotal(assortmentProduct: EecomTpAssortmentProduct, totalFinishesPrice: any, totalQuantites: number) {
      return formatPrice(getSumOfTwoNumbersWithFractionDigits(this.getProductAmount(assortmentProduct, totalQuantites), totalFinishesPrice, CONSTANT.COMMON.FRACTION_DIGITS))
    },

    getTypeKey(validationRule: any, customization: any) {
      if (customization?.eecomTpCustomization?.expertMode) {
        return "expertMode"
      } else {
        switch (validationRule) {
          case CONSTANT.PLAYER_CONFIGURATIONS.REGEX.INITIALS:
            return "initials"
          case CONSTANT.PLAYER_CONFIGURATIONS.REGEX.PLAYER_NAME:
            return "playername"
          case CONSTANT.PLAYER_CONFIGURATIONS.REGEX.NUMBER:
            return "number"
          default:
            return "initials"
        }
      }
    },

    updateCustomizationValueObjectByType(child: any, customizationType: string, customization: any, value: any, index: number, amount: number) {
      const validationRule = customization?.eecomTpCustomization?.validationRule
      const typeKey = this.getTypeKey(validationRule, customization)
      const key = `ch_${child.id}_c_${customization.id}_i_${index}_a_${amount}`
      if (value) {
        this.customizationTypeObject[typeKey][customizationType][key] = value
      } else {
        delete this.customizationTypeObject[typeKey][customizationType][key]
      }
    },

    handleCustomizationByType(customizationType: any, lineItems: any) {
      if (!this.finalConfiguration) {
        return []
      }
      this.finalConfiguration?.children?.forEach((child: any) => {
        if (lineItems[child.id]) {
          for (let i = 0; i < lineItems[child.id]?.quantity; i++) {
            if (child[customizationType] && child[customizationType].length > 0) {
              child[customizationType].forEach((customization: any) => {
                if (lineItems[child.id] && lineItems[child.id].options[customization.id] && lineItems[child.id].options[customization.id].values) {
                  lineItems[child.id].options[customization.id].values.forEach((item: any, index: number) => {
                    const _amount = i > 0 ? i + 1 : 1
                    if (item.amount === _amount) {
                      this.updateCustomizationValueObjectByType(child, customizationType, customization, item.value, index, _amount)
                    }
                  })
                }
              })
            }
          }
        }
      })
    },

    calculateFinishesPriceInNumber(
      currentAssortmentItemObject: Object | undefined,
      assortmentProduct: EecomTpAssortmentProduct,
      currentContext: Context | null,
      totalChildrenValue: number
    ) {
      const eecomTpAssortmentConfiguration = JSON.parse(_getValue(currentAssortmentItemObject, "configuration", "{}").value)
      const lineItems = _getValue(eecomTpAssortmentConfiguration, "items[0].lineItem", {}).value
      let total = 0
      let nonUserInputTotal = 0
      let userInputTotal = 0

      if (totalChildrenValue > 0) {
        const mandatoryCustomizations = assortmentProduct?.eecomTpOfferMandatoryOptions ?? []
        const optionalCustomizations = assortmentProduct?.eecomTpOfferOptionalOptions ?? []
        const allCustomisations = [...mandatoryCustomizations, ...optionalCustomizations]

        Object.keys(lineItems).forEach((key) => {
          const lineItem = lineItems[key]

          allCustomisations.forEach((customisation) => {
            const optionKey = lineItem.options ? Object.keys(lineItem.options).find((optionKey) => optionKey === customisation.id) : null
            const option = optionKey ? lineItem.options[optionKey] : null
            if (option) {
              const count = customisation.eecomTpCustomization.type !== CONSTANT.PLAYER_CONFIGURATIONS.TYPE.USER_INPUT ? lineItem.quantity : option.values.filter((o: any) => o.value).length
              const price = getOptionPrice(assortmentProduct, customisation, count, currentContext?.context?.taxState, currentContext?.currency?.id)
              if (customisation.eecomTpCustomization.type !== CONSTANT.PLAYER_CONFIGURATIONS.TYPE.USER_INPUT) {
                nonUserInputTotal = getSumOfTwoNumbersWithFractionDigits(nonUserInputTotal, getTotalValue(count, price), CONSTANT.COMMON.FRACTION_DIGITS)
              } else {
                userInputTotal = getSumOfTwoNumbersWithFractionDigits(userInputTotal, getTotalValue(count, price), CONSTANT.COMMON.FRACTION_DIGITS)
              }
            }
          })
        })
        total = getSumOfTwoNumbersWithFractionDigits(nonUserInputTotal, userInputTotal, CONSTANT.COMMON.FRACTION_DIGITS)
      }
      return total
    },

    calculateProductAmounts(assortmentProduct: any, currentAssortmentItemObject: any, totalQuantites: number, currentContext: any) {
      const productAmount = this.getProductAmount(assortmentProduct, totalQuantites)
      const calculateFinishesPriceInNumber = this.calculateFinishesPriceInNumber(currentAssortmentItemObject, assortmentProduct, currentContext, totalQuantites)
      const calculateFinishesPrice = `+ ${formatPrice(calculateFinishesPriceInNumber)}*`
      const subTotal = formatPrice(getSumOfTwoNumbersWithFractionDigits(productAmount, calculateFinishesPriceInNumber, CONSTANT.COMMON.FRACTION_DIGITS))
      return {
        productAmount: `${formatPrice(productAmount)}*`,
        calculateFinishesPrice: calculateFinishesPrice,
        subTotal: subTotal,
      }
    },

    async updateConfigurationInIndexDB(assortmentProduct: EecomTpAssortmentProduct, lineItems: any, dbService: any, dbStoreName: string, isUserConsent: boolean) {
      const id = _getValue(assortmentProduct, "id", CONSTANT.COMMON.FIELD_NULL).value
      await dbService.connectWithIndexedDb()
      const assortmentItemObject = await dbService.get(dbStoreName, assortmentProduct?.id)
      const indexDBUpdatedAt = JSON.parse(_getValue(assortmentItemObject, "updatedAt", 0).value)
      const indexDBUpdatedAtTimestamp = new Date(indexDBUpdatedAt).getTime()

      const tempCartObj = prepareCartObject(assortmentProduct, isUserConsent, true, lineItems, this.finalConfiguration, false)
      const obj = {id: id, configuration: JSON.stringify(tempCartObj), updatedAt: indexDBUpdatedAtTimestamp}
      await dbService.set(dbStoreName, obj)
      IndexDBChanged.setIndexDBChangedSuccess(true)

      this.restCustomizationTypeObject()
      this.handleCustomizationByType("mandatoryCustomization", lineItems)
      this.handleCustomizationByType("optionalCustomization", lineItems)
    },

    async autoSavePlayerConfiguration(assortmentProduct: EecomTpAssortmentProduct, dbService: IndexedDbService, dbStoreName: string) {
      // return;
      const assortmentProductId = _getValue(assortmentProduct, "id", null).value
      if (!assortmentProductId) {
        return null
      }
      const eecomTpAssortmentConfiguration = await this.fetchCurrentAssortmentProductConfiguration(assortmentProduct.id)
      const result = await dbService.get(dbStoreName, assortmentProductId)
      const indexDbSavedConfiguration = JSON.parse(_getValue(result, "configuration", "{}").value)

      if (!isEmpty(indexDbSavedConfiguration)) {
        if (indexDbSavedConfiguration && indexDbSavedConfiguration.items && indexDbSavedConfiguration.items.length > 0) {
          indexDbSavedConfiguration.items[0].lineItem = removeEmptyOptionalCustomizationV2(this.finalConfiguration, indexDbSavedConfiguration.items[0].lineItem, true)
        } else {
          await dbService.delete(dbStoreName, assortmentProductId)
        }
      }
      //configuration save logic in shopware db
      if (!isEmpty(indexDbSavedConfiguration) && !isEqual(eecomTpAssortmentConfiguration, indexDbSavedConfiguration)) {
        return await this.setConfiguration(false, {id:assortmentProduct.id,customerConfiguration: indexDbSavedConfiguration})
      }
      return null
    },

    getTotalPlayerCount(finalConfiguration: FinalConfiguration) {
      let total = 0
      if (!finalConfiguration || !finalConfiguration.children || finalConfiguration.children.length === 0) {
        return 0
      }
      finalConfiguration.children.forEach((child: any) => {
        total += +child.playerConfiguration.quantity
      })
      return total
    },

    async fetchCurrentAssortmentProductConfiguration(eecomTpAssortmentProductId: string) {
      let eecomTpAssortmentConfiguration = {}
      const customerStore = useCustomerStore()

      const customerId = _getValue(customerStore.customer, "id", "").value
      if (customerId) {
        const reqBodyParams = {
          totalCountMode: CONSTANT.BODY_PARAMS.TOTAL_COUNT_MODE_DEFAULT,
          limit: CONSTANT.COMMON.DEFAULT_PAGE_SIZE,
          page: 1,
          associations: CONSTANT.BODY_PARAMS.ASSOCIATIONS.ASSOCIATIONS_ASSORTMENT_PRODUCTS_BY_CUSTOMER_ID,
          sort: [
            {
              field: "eecomTpAssortmentProducts.eecomTpAssortmentProductMedia.position",
              order: "ASC",
              naturalSorting: true,
            },
            {
              field: "name",
              order: "ASC",
              naturalSorting: false,
            },
          ],
          filter: [
            {
              type: "equals",
              field: "active",
              value: true,
            },
            {
              type: "equals",
              field: "eecomTpAssortmentProducts.id",
              value: eecomTpAssortmentProductId,
            },
          ],
        }
        const productRangeStore = useProductRangeStore()
        const result = (await productRangeStore.fetchAssortmentDetailsByCustomerId(customerId, reqBodyParams, false)) as ApiResponse
        if (result && (result.status === CONSTANT.HTTP_STATUS.OK || result.status === CONSTANT.HTTP_STATUS.NO_CONTENT)) {
          const productsArray = _getValue(result, "data.elements[0].eecomTpAssortmentProducts").value
          const tempEecomTpAssortmentProduct = productsArray?.find((product: any) => product.id === eecomTpAssortmentProductId)
          eecomTpAssortmentConfiguration = _getValue(
            tempEecomTpAssortmentProduct,
            `eecomTpAssortmentConfiguration[${get(tempEecomTpAssortmentProduct, "eecomTpAssortmentConfiguration.length", 1) - 1}].configuration`,
            {}
          ).value
        }
      }
      return eecomTpAssortmentConfiguration
    },

    extractCustomizationsData(lineItems: any, t: any) {
      return Object.keys(lineItems).flatMap((key) => {
        const child = this.finalConfiguration?.children.find((child: any) => child.id === key)
        const options = lineItems[key]?.options

        if (!child || !options) return []

        const userInputOptions = Object.entries(options).filter(([, option]: any) => option.type === "user-input")

        return userInputOptions
          .map(([optionKey, {values}]: any) => {
            const allCustomisations = [...child.mandatoryCustomization, ...child.optionalCustomization]
            const c = allCustomisations.find((option) => option.id === optionKey)
            const {customizationType} = getCustomizationNameFromOption(c.eecomTpCustomization, false, t)

            const customizationValues = values.reduce((acc: { [x: string]: any }, {amount, value}: any) => {
              acc[amount] = value
              return acc
            }, {})

            return Object.keys(customizationValues).map((amount) => ({
              [customizationType]: customizationValues[amount],
            }))
          })
          .flat()
      })
    },

    convertArrayOfObjectsToArray(arr: any[], keys: string[]) {
      return arr.map((obj: any) => keys.map((key: any) => obj[key] ?? ""))
    },
    updateAssortmentProductConfigs(config: any, assortmentConfigs: any) {
      const lineItems = config.lineItem;
      for (const childId in lineItems) {
        if (lineItems.hasOwnProperty(childId)) {
          const lineItem = lineItems[childId];
          const assortmentConfig = assortmentConfigs.find((ac: any) => ac.productId === childId);
          if (assortmentConfig) {
            for (const customizationId in lineItem.options) {
              if (lineItem.options.hasOwnProperty(customizationId)) {
                const option = lineItem.options[customizationId];
                const optionalConfig = assortmentConfig.eecomTpOfferOptionalConfig.find((opt: any) => opt.eecomTpOfferOptionalOptionId === customizationId);
                if (optionalConfig) {
                  if (optionalConfig.quantity > 0) {
                    optionalConfig.value = option.values;
                  } else {
                    optionalConfig.value = [];
                  }
                }
                const mandatoryConfig = assortmentConfig.eecomTpOfferMandatoryConfig.find((opt: any) => opt.eecomTpOfferMandatoryOptionId === customizationId);
                if (mandatoryConfig) {
                  if (mandatoryConfig.quantity > 0) {
                    mandatoryConfig.value = option.values;
                  } else {
                    mandatoryConfig.value = [];
                  }
                }
              }
            }
          }
        }
      }
    },
    createOptionMaps(offerProduct: any) {
      const mandatoryCustomizations = get(offerProduct, "eecomTpOfferMandatoryOptions", [])
      const optionalCustomizations = get(offerProduct, "eecomTpOfferOptionalOptions", [])

      const mandatoryOptionMap: any = new Map(mandatoryCustomizations.map((cust: any) => [cust.id, cust.eecomTpCustomizationId]));
      const optionalOptionMap: any = new Map(optionalCustomizations.map((cust: any) => [cust.id, cust.eecomTpCustomizationId]));

      return {mandatoryOptionMap, optionalOptionMap};
    },

    getEecomTpCustomizationIdByOptionId(offerProduct: any, id: string): string | undefined {
      const {mandatoryOptionMap, optionalOptionMap} = this.createOptionMaps(offerProduct);

      if (mandatoryOptionMap.has(id)) {
        return mandatoryOptionMap.get(id);
      }

      if (optionalOptionMap.has(id)) {
        return optionalOptionMap.get(id);
      }

      return undefined;
    },


// Function to create maps for customizations
    createCustomizationMaps(offerProduct: any) {
      const mandatoryCustomizations = get(offerProduct, "eecomTpAssortmentProduct.eecomTpMandatoryCustomizations", [])
      const optionalCustomizations = get(offerProduct, "eecomTpAssortmentProduct.eecomTpOptionalCustomizations", [])

      const mandatoryCustomizationMap: any = new Map(mandatoryCustomizations.map((cust: any) => [cust.eecomTpCustomizationId, cust.id]));
      const optionalCustomizationMap: any = new Map(optionalCustomizations.map((cust: any) => [cust.eecomTpCustomizationId, cust.id]));

      return {mandatoryCustomizationMap, optionalCustomizationMap};
    },

// Function to get customization id by passing eecomTpCustomizationId
    getCustomizationId(offerProduct: any, eecomTpCustomizationId: string): string | undefined {
      const {mandatoryCustomizationMap, optionalCustomizationMap} = this.createCustomizationMaps(offerProduct);

      if (mandatoryCustomizationMap.has(eecomTpCustomizationId)) {
        return mandatoryCustomizationMap.get(eecomTpCustomizationId);
      }

      if (optionalCustomizationMap.has(eecomTpCustomizationId)) {
        return optionalCustomizationMap.get(eecomTpCustomizationId);
      }

      return undefined;
    },
    async setConfiguration(isLoader: boolean = true, bodyParams: any) {
      try {
        const setConfigurationUrl = CONSTANT.API.ORDER_STATE.OFFER_ASSORTMENT_PRODUCT_CONFIG;
        let result: any
        if (isLoader) {
          result = await httpService.post(setConfigurationUrl, bodyParams)
        } else {
          result = await httpServiceWithoutLoader.post(setConfigurationUrl, bodyParams)
        }
        return result
      } catch (error) {
        return get(error, `response.data.errors`, [])
      }
    },
    async onDownload(assortmentProduct: EecomTpAssortmentProduct, dbService: any, dbStoreName: string, t: any) {
      const teamOrderStore = useTeamOrderStore()
      let eecomAssortmentName = get(this.offerAssortmentProducts, 'elements[0].name', get(this.offerAssortmentProducts, "elements[0].translated.name", ""))
      const productsInfo = this.assortmentProductsInfo?.find((item: any) => item?.id === assortmentProduct?.id)
      const fileName = teamOrderStore.createFileName(eecomAssortmentName, productsInfo.name)
      const assortmentItemObject = await dbService.get(dbStoreName, assortmentProduct?.id)
      const configuration = parseConfigurationFromItemObject(assortmentItemObject)
      const lineItems = configuration?.lineItem

      const resultArr = this.extractCustomizationsData(lineItems, t)
      const rows: string[][] = this.convertArrayOfObjectsToArray(resultArr, ["playerName", "number", "initials", "expertMode"])
      const csvContent = teamOrderStore.convertToCSV(rows, t)
      downloadCSV(fileName, csvContent)
    },
    async addToCart(bodyParams: AddToCart) {
      try {
        return await httpService.post(CONSTANT.API.TEAM_ORDER.ADD_TO_CART, bodyParams)
      } catch (error) {
        return get(error, `response.data.errors`, [])
      }
    },
  },
})
